<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    item-text="text"
    item-value="value"
  >
  </v-select>
</template>

<script>
export default {
  props: {
    allOption: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [
      { text: "Push Pay", value: "pushpay" },
      { text: "Venmo", value: "venmo" },
      { text: "Paypal", value: "paypal" }
    ]
  }),
  mounted() {
    if (this.allOption) {
      this.items.push({
        text: "All",
        value: "all"
      })
    }
  }
};
</script>
